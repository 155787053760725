/**
# See Vyoma LICENSE file https://gitlab.com/vyoma_systems/common/-/blob/main/LICENSE.vyoma for details
*/
import React from "react";
import PropTypes from "prop-types";
import { useState } from "react";

// Soft UI Dashboard React icons
import Shop from "examples/Icons/Shop";
//import Office from "examples/Icons/Office";
//import Settings from "examples/Icons/Settings";
import Document from "examples/Icons/Document";
import SpaceShip from "examples/Icons/SpaceShip";
import CustomerSupport from "examples/Icons/CustomerSupport";
//import CreditCard from "examples/Icons/CreditCard";
//import Cube from "examples/Icons/Cube";

// Soft UI Dashboard React layouts
import Dashboard from "layouts/dashboard";
//import Tables from "layouts/tables";
//import Billing from "layouts/billing";
//import VirtualReality from "layouts/virtual-reality";
//import IclassArtifacts from "layouts/artifacts/iclass";
//import CclassArtifacts from "layouts/artifacts/cclass";
//import RTL from "layouts/rtl";
import Profile from "layouts/profile";
//import Analysis from "layouts/analysis";
//import Project from "layouts/project";
import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";
import SignOut from "layouts/authentication/sign-out";

//import AuthApi from "../api/auth";

const AuthContext = React.createContext(null);

export const AuthProvider = ({ userData, children }) => {
  let [user, setUser] = React.useState(userData);
  user = typeof user === "string" ? JSON.parse(user) : user;

  const [routes, setRoutes] = useState([
    {
      type: "collapse",
      name: "Projects",
      key: "projects",
      route: "/projects",
      icon: <Shop size="12px" />,
      component: Dashboard,
      noCollapse: true,
      protected: true,
    },
    { type: "title", title: "Account Pages", key: "account-pages" },
    {
      type: "collapse",
      name: "Profile",
      key: "profile",
      route: "/profile",
      icon: <CustomerSupport size="12px" />,
      component: Profile,
      noCollapse: true,
      protected: true,
    },
    //    {
    //      type: "collapse",
    //      name: "Analysis",
    //      key: "analysis",
    //      route: "/analysis",
    //      icon: <CreditCard size="12px" />,
    //      component: Analysis,
    //      noCollapse: true,
    //      protected: true,
    //    },
    {
      type: "none",
      name: "Sign In",
      key: "sign-in",
      route: "/authentication/sign-in",
      icon: <Document size="12px" />,
      component: SignIn,
      noCollapse: true,
    },
    // {
    //   type: "none",
    //   name: "Sign Up",
    //   key: "sign-up",
    //   route: "/authentication/sign-up",
    //   icon: <SpaceShip size="12px" />,
    //   component: SignUp,
    //   noCollapse: true,
    // },
    {
      type: "collapse",
      name: "Logout",
      key: "sign-out",
      route: "/authentication/sign-out",
      icon: <SpaceShip size="12px" />,
      component: SignOut,
      noCollapse: true,
    },
  ]);

  return (
    <AuthContext.Provider value={{ user, setUser, routes, setRoutes }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  userData: PropTypes.any,
  children: PropTypes.any,
};

export const useAuth = () => React.useContext(AuthContext);
