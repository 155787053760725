/**
# See Vyoma LICENSE file https://gitlab.com/vyoma_systems/common/-/blob/main/LICENSE.vyoma for details
*/
import axios from "./index";

class AuthApi {
  static Login = (data) => {
    return axios.post(`${base}/login`, data);
  };

  static Register = (data) => {
    return axios.post(`${base}/register`, data);
  };

  static Logout = (data) => {
    return axios.post(`${base}/logout`, data, { headers: { Authorization: `${data.token}` } });
  };

  static devworkspace = (data) => {
    return axios.get(`${base}/workspace`, { headers: { Authorization: `${data.token}` } });
  };

  static devcontainer = (data) => {
    return axios.post(`${base}/container_workspace`, data, {
      headers: { Authorization: `${data.user.token}` },
    });
  };

  static delete_devcontainer = (data) => {
    return axios.post(`${base}/del_cont_workspace`, data, {
      headers: { Authorization: `${data.user.token}` },
    });
  };

  static workspace_delete = (data) => {
    return axios.get(`${base}/delete_workspace`, { headers: { Authorization: `${data.token}` } });
  };

  static Artifact = (data) => {
    return axios.post(`${base}/artifacts`, data, {
      headers: { Authorization: `${data.user.token}` },
    });
  };

  static ArtifactDownload = (data) => {
    return axios.post(`${base}/download_artifact`, data, {
      headers: { Authorization: `${data.user.token}` },
      responseType: "blob",
    });
  };

  static ArtifactDevDownload = (data) => {
    return axios.post(`${base}/dev_artifact`, data, {
      headers: { Authorization: `${data.user.token}` },
    });
  };

  static PipelineTrigger = (data) => {
    return axios.post(`${base}/pipeline_trigger`, data, {
      headers: { Authorization: `${data.user.token}` },
    });
  };

  static FileUpload = (data) => {
    return axios.post(`${base}/artifact_upload`, data.formData, {
      headers: { Authorization: `${data.user.token}`, "Content-Type": "multipart/form-data" },
    });
  };

  static PermList = (data) => {
    return axios.get(`${base}/permlist`, { headers: { Authorization: `${data.token}` } });
  };

  static ChangePwd = (data) => {
    return axios.post(`${base}/change_pwd`, data, {
      headers: { Authorization: `${data.user.token}` },
    });
  };

  static Analysis = (data) => {
    return axios.post(`${base}/usage_check`, data, {
      headers: { Authorization: `${data.user.token}` },
    });
  };
}

let base = "users";

export default AuthApi;
